import React, { useState, useEffect, forwardRef, Fragment } from "react";
import { message, Typography } from "antd";
import MUIDataTable from "mui-datatables";
import { Button, Dialog, DialogTitle, Divider, DialogContent, Table, tableCellClasses, TableBody, TableRow, TableCell, TextField, Autocomplete, DialogActions, Box, Modal, TableContainer, TableHead, Paper } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';


import { useDispatch, useSelector } from "react-redux";
import {
    getNotificationAlerts,
    getOrderDetails
} from "../../../redux/slices/crmSlice";

const Notifications = () => {
    const dispatch = useDispatch();
    const { notificationAlerts, orderDetails } = useSelector(
        (state) => state.crm.data
    );

    const [page, setPage] = useState(1);
    const [tableLoader, setTableLoader] = useState(false)
    const [tab, setTab] = useState("notification");
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        setTableLoader(true)
        dispatch(getNotificationAlerts({ page, tab }))
            .then(() => {
                setTableLoader(false)
            })
    }, [page, tab])

    function dateConverter(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        let year = date.getFullYear();
        return `${mnth}-${year}`
    };

    function dateConverter(str) {
        var [day, month, year] = str.split('-');
        return `${month}-${year}`;
    }

    function dateConverterForModal(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        let year = date.getFullYear();
        return `${day}-${mnth}-${year}`
    };

    const handleOrderDetails = (rowData) => {
        let order_month = dateConverter(rowData[0])
        let store_id = rowData[5]
        let partner_id = rowData[6]
        dispatch(getOrderDetails({
            order_month,
            store_id,
            partner_id
        }))
            .then(() => {
                setOpenModal(true)
            })
    }

    const tableColumnsForNotification = [
        {
            name: "order_month",
            label: "Order Month",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => dateConverter(value) || "-",
            },
        },
        {
            name: "store_name",
            label: "Store Name",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => value || "-",
            },
        },
        {
            name: "partner",
            label: "Partner Name",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => value || "-",
            },
        },
        {
            name: "count",
            label: "No. of orders",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => (
                    <Button onClick={() => handleOrderDetails(tableMeta.rowData)} sx={{
                        padding: 0,
                    }}>
                        {value}
                    </Button>
                ),
            },
        },
        {
            name: "points",
            label: "Points",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => value || 0,
            },
        },
        {
            name: "store_id",
            label: "store_id",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => value || 0,
                display: 'none'
            },
        },
        {
            name: "partner_id",
            label: "partner_id",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => value || 0,
                display: 'none'
            },
        },
    ];

    const options = {
        filterType: "checkbox",
        selectableRows: "none",
        responsive: "vertical",
        rowsPerPage: 10,
        rowsPerPageOptions: [10],
        count: notificationAlerts?.count || 0,
        page: page - 1,
        onChangePage: (currentPage) => {
            console.log(currentPage, "currentPage");
            setPage(currentPage + 1);
        },
        onFilterChange: (changedColumn, filterList) => {
            const activeFilters = filterList.reduce((acc, filters, index) => {
                if (filters.length > 0) {
                    acc[tableColumns[index].name] = filters.map((value) =>
                        value === "Null" ? null : value
                    );
                }
                return acc;
            }, {});

            const newFilteredData = data.filter((row) => {
                return Object.entries(activeFilters).every(([key, values]) =>
                    values.includes(row[key])
                );
            });

            setFilteredData(newFilteredData);

        },
        searchProps: {
            onKeyUp: (e) => {
                if (e.key === "Enter") {
                    setSearchText(e.target.value);
                }
            },
        },
        pagination: true,
        serverSide: true,
        search: true,
        viewColumns: true,
        enableNestedDataAccess: ".",

        searchPlaceholder: "Search in all columns...",
        textLabels: {
            pagination: {
                next: "Next",
                previous: "Previous",
                rowsPerPage: "100",
                displayRows: "of",
            },
        },
    };

    return (
        <Fragment>
            {
                tableLoader ?
                    <Box sx={{
                        width: '100%',
                        height: '35rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <CircularProgress />
                    </Box> :
                    <>
                        <MUIDataTable
                            title={
                                <Box>
                                    <h2 style={{ margin: 0 }}>
                                        Notification
                                    </h2>
                                    <Typography sx={{ margin: 0, fontWeight: 400, fontSize: '16px' }}>
                                        *Contains all orders whose estimated approval date is between 3-15 days.
                                    </Typography>
                                </Box>
                            }
                            rowsPerPage={10}
                            rowsPerPageOptions={[10]}
                            data={notificationAlerts?.data ?? []}
                            columns={tableColumnsForNotification}
                            options={options}
                        />

                        <Modal open={openModal} onClose={() => setOpenModal(false)}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: '75% ',
                                    maxHeight: "80vh",
                                    bgcolor: "background.paper",
                                    boxShadow: 24,
                                    p: 4,
                                    borderRadius: 2,
                                    overflowY: "auto",
                                }}
                            >
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    Order Details
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Unique ID</TableCell>
                                                <TableCell>User ID</TableCell>
                                                <TableCell>User Name</TableCell>
                                                <TableCell>Store Name</TableCell>
                                                <TableCell>Campaign Name</TableCell>
                                                <TableCell>Sale Amount</TableCell>
                                                <TableCell>Click ID</TableCell>
                                                <TableCell>Order ID</TableCell>
                                                <TableCell>Payout</TableCell>
                                                <TableCell>Point Post Payout</TableCell>
                                                <TableCell>Conversion Date</TableCell>
                                                <TableCell>Action Date</TableCell>
                                                <TableCell>Estimated Approval Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {orderDetails?.map((order, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{order.unique_id}</TableCell>
                                                    <TableCell>{order.user_id}</TableCell>
                                                    <TableCell>{order.user_name}</TableCell>
                                                    <TableCell>{order.store_name}</TableCell>
                                                    <TableCell>{order.campaign_name}</TableCell>
                                                    <TableCell>{order.sale_amount}</TableCell>
                                                    <TableCell>{order.click_id}</TableCell>
                                                    <TableCell>{order.order_id}</TableCell>
                                                    <TableCell>{order.payout}</TableCell>
                                                    <TableCell>{order.point_post_payout}</TableCell>
                                                    <TableCell>{dateConverterForModal(order.conversion_date)}</TableCell>
                                                    <TableCell>{dateConverterForModal(order.action_date)}</TableCell>
                                                    <TableCell>{dateConverterForModal(order.est_approval_date)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Button
                                    variant="contained"
                                    onClick={() => setOpenModal(false)}
                                    sx={{ mt: 2, display: "block", marginLeft: "auto" }}
                                >
                                    Close
                                </Button>
                            </Box>
                        </Modal>
                    </>
            }
        </Fragment>
    );
};

export default Notifications;
