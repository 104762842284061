import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { showBrokenLink } from "../../../redux/slices/manageStoreSlice";
import { updateBrokenLink } from "../../../redux/api/manageStoresApi";
import { getCountForLayout } from "../../../redux/slices/layoutSlice";
const BrokenLinksTable = () => {
  const dispatch = useDispatch();
  const { brokenLinkData } = useSelector((state) => state.manageStores);

  useEffect(() => {
    dispatch(showBrokenLink());
  }, [dispatch]);

  const handleUpdate = async (link, store_id) => {
    try {
      const response = await updateBrokenLink({
        link,
        store_id
      });
      console.log(response, "response");
      if (response.data.code === 200) {
        dispatch(showBrokenLink());
        dispatch(getCountForLayout())
      }

    } catch (error) {
      console.error("Error updating link:", error);
    }
  };
  const brokenLinks = brokenLinkData.filter((link) => link.is_broken === 1);

  const handleFalsePositive = async (link, store_id) => {
    try {
      const response = await updateBrokenLink({
        link,
        store_id,
        false_positive: true
      });
      if (response.data.code === 200) {
        dispatch(showBrokenLink());
        dispatch(getCountForLayout())
      }
    } catch (error) {
      console.error("Error updating link:", error)
    }
  }

  return (
    <div>
      <h2>Broken Links</h2>
      <table>
        <thead>
          <tr>
            <th>Store Name</th>
            <th>Link</th>
            <th>Break Time</th>
            <th>Action</th>
            <th>False Positive</th>
          </tr>
        </thead>
        <tbody>
          {brokenLinks.map((link) => (
            <tr key={link.id}>
              <td>{link.store_name}</td>
              <td>{link.link}</td>
              <td>{new Date(link.break_time).toLocaleString()}</td>
              <td>
                <button onClick={() => handleUpdate(link.link, link.store_id)}>Resolved</button>
              </td>
              <td>
                <button onClick={() => handleFalsePositive(link.link, link.store_id)}>False Positive</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BrokenLinksTable;
