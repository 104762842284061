import React, { useState, useEffect, forwardRef, Fragment } from "react";
// import styled from "styled-components";
// import { Typography, Radio } from "antd";
import MaterialTable from "material-table";

import { DatePicker, Space, message } from "antd";
// import {  } from "antd";
const { RangePicker } = DatePicker;

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { bulkBuyReportsData } from "../../../redux/slices/crmSlice";
import moment from "moment";

// const { Title } = Typography;
// const Wrapper = styled.div`
//   width: 100%;
// `;
// const BodyContainer = styled.div``;

// const RadioContainer = styled.div`
//   width: 50%;
//   display: flex;
//   flex-direction: row;
//   padding: 5px 0;
//   > div {
//     color: #000000;
//     font-size: 18px;
//     font-weight: bold;
//     margin-right: 30px;
//   }
// `;

const BulkBuyReports = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.crm.data.bulkbuyResultsData);

  //   console.log(bulkbuyResultsData, "bulkbuyResultsData");
  const performRepoCrm = data?.map((o) => ({ ...o }));

  const [dateData, setDateData] = useState({
    start_date: "",
    end_date: "",
  });
  const [exeededcondition, setexeededcondition] = useState(false);
  const [checked, setChecked] = React.useState(true);
  const [typeGroup, setTypeGroup] = React.useState(["users"]);

  //   const handleChange = (event) => {
  //     setChecked(event.target.checked);
  //     if (event.target.checked) {
  //       setTypeGroup([...typeGroup, event.target.value]);
  //     } else {
  //       setTypeGroup(typeGroup.filter((value) => value !== event.target.value));
  //     }
  //   };

  const handleChange = (event) => {
    const { checked, value } = event.target;
    setChecked(checked);
    // console.log(value, "value============", checked, "checked============");
    if (checked) {
      setTypeGroup((prevTypeGroup) => {
        if (!prevTypeGroup.includes(value)) {
          return [...prevTypeGroup, value];
        }
        return prevTypeGroup;
      });
    } else {
      setTypeGroup((prevTypeGroup) =>
        prevTypeGroup.filter((type) => type !== value)
      );
    }
  };
  // console.log(typeGroup, "typeGroup============");
  const onDateChange = (value, dateString) => {
    setDateData({
      ...dateData,
      start_date: dateString[0],
      end_date: dateString[1],
    });
  };

  useEffect(() => {
    let data = { typeGroupBy_arr: typeGroup };
    // setTypeGroup([...typeGroup, "ts_name"]);
    console.log(data, "data============");
    if (typeGroup.length === 0) {
      setTypeGroup(["users"]);
    }
    dispatch(bulkBuyReportsData(data));
  }, [typeGroup]);

  const dateRangeCheck = async (e) => {
    e.preventDefault();
    let data = {};
    data.start_date = dateData.start_date;
    data.end_date = dateData.end_date;
    data.typeGroupBy_arr = typeGroup;
    if (exeededcondition) {
      message.error("Date range cannot exceed 62 days");
      return;
    }
    dispatch(bulkBuyReportsData(data));
    ////not dispatching yet rtk
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  const onCalendarChange = (dates, dateStrings, info) => {
    if (dates) {
      // If a start date is selected (first selection)
      if (dates[0] && !dates[1]) {
        // Do nothing, wait for end date selection
        return;
      }
    }
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const tableColumns = [
    { title: "Store/Task Name", field: "ts_name", editable: "never" },
    { title: "partner", field: "partner", editable: "never" },
    { title: "status", field: "status", editable: "never" },
    // { title: "Conversions", field: "unique_id", editable: "never" },
    // { title: "Clicks", field: "click_id", editable: "never" },
    // {
    //   field: "unique_id",
    //   title: "Conversion Rate",
    //   render: (rowData) => (
    //     <p>{((rowData.unique_id / rowData.click_id) * 100).toFixed(2)}</p>
    //   ),
    // },
    { title: "User Name", field: "users", editable: "never" },
    { title: "User Id", field: "uid", editable: "never" },

    {
      title: "Point Post Payout",
      feild: "point_post_payout",
      render: (rowData) => <p>{rowData?.point_post_payout?.toFixed(2)}</p>,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      title: "Sale Amount",
      field: "sale_amount",
      render: (rowData) => <p>{rowData?.sale_amount?.toFixed(2)}</p>,
      editable: "never",
    },
    { title: "Payout", field: "payout", editable: "never" },
    { title: "Total Number", field: "count_payout", editable: "never" },
  ];

  return (
    <Fragment>
      <div className="my-3">
        <label>Date Range:--</label>
        <RangePicker
          onChange={onDateChange}
          onCalendarChange={onCalendarChange}
          disabledDate={disabledDate}
          showTime={false}
        // format="YYYY-MM-DD"
        />
        <button className="btn btn-primary ml-4" onClick={dateRangeCheck}>
          Submit
        </button>
      </div>
      {/* status, ts_name, partner  */}
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              //   defaultChecked
              value={"status"}
              name={"status"}
              checked={typeGroup.includes("status")}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Status"
        />
        <FormControlLabel
          control={
            <Checkbox
              value={"users"}
              name={"users"}
              checked={typeGroup.includes("users")}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Users"
        />

        <FormControlLabel
          control={
            <Checkbox
              value={"ts_name"}
              name={"ts_name"}
              checked={typeGroup.includes("ts_name")}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Task Store"
        />
        <FormControlLabel
          control={
            <Checkbox
              value={"partner"}
              name={"partner"}
              checked={typeGroup.includes("partner")}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Partner"
        />
      </FormGroup>
      <MaterialTable
        title="Complete Orders Details.. "
        columns={tableColumns}
        icons={tableIcons}
        data={performRepoCrm && performRepoCrm}
        // options={{ search: true, actionsColumnIndex: -1, exportButton: true, allColumns: true,}}
        options={{
          actionsColumnIndex: -1,
          selection: true,
          showFirstLastPageButtons: true,
          pageSize: 10,
          padding: "dense",
          pageSizeOptions: [
            10,
            20,
            performRepoCrm ? performRepoCrm.length : 100,
          ],
          exportButton: true,
        }}
      />
    </Fragment>
  );
};

export default BulkBuyReports;
