import React, { Fragment, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
  Input, message, Select
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { manualBonusPoints, statusMasterData } from "../../redux/slices/manualBonusPointsSlice"
const { Option } = Select;
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';

const { TextArea } = Input;

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  > span {
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.div`
  width: 140px;
  height: 40px;
  display: flex;
  background: #0000ff;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  > p {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
  }
`;
const DataRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #000000;
    font-weight: bold;
    font-size: 14px;
    margin-right: 30px;
  }
`;

const ManualBonusPoints = () => {
  const dispatch = useDispatch();
  const { userNameData, redemptionStatus, bonusPoints } = useSelector(s => s.manualBonus)
  const [allValues, setAllValues] = useState({
    user_id: "",
    points: "",
    initial_status: "",
    remark: "",
  });
  const [loader, setLoader] = useState(false)

  function handleChange(value) {
    console.log(value, "value")
    setAllValues({ ...allValues, initial_status: value });
    if (redemptionStatus.length > 0) {
      for (var i = 0; i < redemptionStatus.length; i++) {
        if (value == redemptionStatus[i].status) {
          setAllValues({
            ...allValues,
            initial_status: redemptionStatus[i].status,
          });
        }
      }
    }
    // if (value == "rejected") {
    //   setRequire(true);
    // }
  }

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
    // console.log(e);
  };

  useEffect(() => {
    dispatch(statusMasterData());
    dispatch(manualBonusPoints())
  }, []);

  const submitHandler = async (e, type) => {
    e.preventDefault();
    setLoader(true)
    let data = {};
    data.user_id = allValues.user_id;
    data.points = allValues.points;
    data.initial_status = allValues.initial_status;
    data.remark = allValues.remark;
    data.type = type
    dispatch(manualBonusPoints(data))
      .then(() => {
        setLoader(false)
        if (type != "userDetails") {
          setAllValues({
            user_id: "",
            points: "",
            initial_status: "",
            remark: "",
          })
          dispatch(manualBonusPoints())
        }
        message.success(type == "userDetails" ? "Fetched user details successfully" : "Added bonus points successfully.")
      })
      .catch(() => {
        message.error(type == "userDetails" ? "Error fetching user details" : "Error adding bonus points.")
      })
  };

  const columns = [
    {
      name: "uid",
      label: "UID",
    },
    {
      name: "points",
      label: "Points",
    },
    {
      name: "posted_by",
      label: "Posted By",
    },
    {
      name: "remark",
      label: "Remark",
    },
    {
      name: "initial_status",
      label: "Initial Status",
    },
  ];

  const options = {
    selectableRows: 'none',
    rowsPerPage: 100,
    rowsPerPageOptions: [100],
    pagination: false,
    serverSide: true,
    search: true,
    viewColumns: true,
    enableNestedDataAccess: ".",
  }

  return (
    <>
      <Container className="mt-5">
        <form>
          <div className="row">
            <div className="col-12 col-lg-12">
              <DataRow>
                <label for="" className="form-label" style={{ width: "40%" }}>
                  user_id
                </label>
                <p>{userNameData[0] && userNameData[0].name}</p>
                <input
                  type="text"
                  className="form-control w-75"
                  name="user_id"
                  onChange={changeHandler}
                  required={require}
                  value={allValues.user_id}
                />
              </DataRow>
              {
                !userNameData[0] && !userNameData[0]?.name && !loader &&
                <div className="col-12 col-lg-12 my-3 text-center ">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => submitHandler(e, "userDetails")}
                  >
                    Submit
                  </button>
                </div>
              }

              {userNameData[0] && userNameData[0].name &&
                <>
                  <DataRow>
                    <label for="" className="form-label" style={{ width: "40%" }}>
                      points
                    </label>
                    <input
                      type="number"
                      className="form-control w-75"
                      name="points"
                      onChange={changeHandler}
                      required={require}
                      value={allValues.points}
                    />

                  </DataRow>
                  <DataRow>
                    <label
                      for="initial_status"
                      className="form-label"
                      style={{ width: "40%" }}
                    >
                      Status
                      <span className="ml-5" style={{ color: "blue" }}>{`(${allValues.initial_status})`}</span>
                    </label>
                    <Select
                      name="status"
                      id="status"
                      className="w-75"
                      onChange={handleChange}
                    >
                      {redemptionStatus &&
                        redemptionStatus.map(
                          (item, index) => {
                            return (
                              <Option value={item.status} key={index}>
                                {item.status}
                              </Option>
                            );
                          }
                        )}
                    </Select>
                  </DataRow>
                  <DataRow className="mt-5">
                    <label for="" className="form-label" style={{ width: "40%" }}>
                      Remarks
                    </label>
                    <input
                      type="text"
                      className="form-control w-75"
                      name="remark"
                      onChange={changeHandler}
                      required={require}
                      value={allValues.remark}
                    />
                  </DataRow>
                  <div className="col-12 col-lg-12 my-3 text-center ">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={(e) => submitHandler(e, "formDetails")}
                    >
                      Submit
                    </button>
                  </div>
                </>
              }
              {
                loader && <CircularProgress />
              }

            </div>

          </div>
        </form>
      </Container>
      <MUIDataTable
        title={"Bounus Points"}
        data={bonusPoints}
        columns={columns}
        options={options}
      />
    </>
  );
};

export default ManualBonusPoints;
