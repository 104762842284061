import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/manualBonusPointsApi";
import { message } from "antd";



const initialState = {
  userNameData: [],
  redemptionStatus: [],
  bonusPoints: []
}


export const manualBonusPoints = createAsyncThunk(
  "/points/bonuspoints",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.manualBonusPoints(data);
      if (resp.status === 200) {
        return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');

        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);

    }
  }
);

export const statusMasterData = createAsyncThunk(
  "/points/statusmaster",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.statusMasterData();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);


const manualBonusPointsSlice = createSlice({
  name: "manualBonus",
  initialState,
  reducers: {

  },

  extraReducers: (builder) => {
    builder.addCase(manualBonusPoints.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(manualBonusPoints.fulfilled, (state, action) => {
      state.userNameData = action.payload?.data ? action.payload?.data : [];
      state.bonusPoints = action.payload?.bonusPoints
      state.loading = false;
      state.error = null;
    });
    builder.addCase(manualBonusPoints.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =============================================================================================
    builder.addCase(statusMasterData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(statusMasterData.fulfilled, (state, action) => {
      state.redemptionStatus = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(statusMasterData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

  },
});

export const { } = manualBonusPointsSlice.actions;

export default manualBonusPointsSlice.reducer;
