import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/crmApi";
import { message } from "antd";

export const Top10Results = createAsyncThunk(
  "/crm/pendingpayout",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.Top10Results();
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const AllOrdersCrmDetails = createAsyncThunk(
  "/crm/allordersdata",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.AllOrdersCrmDetails(data);
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const DownloadAllData = createAsyncThunk(
  "/crm/downloadall",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.AllOrdersCrmDetails(data);
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const performanceReportsData = createAsyncThunk(
  "/crm/performreport",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.performanceReportsApi(data);
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const bulkBuyReportsData = createAsyncThunk(
  "/crm/bulkbuyersreport",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.bulkbuyReportsApi(data);
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const DownloadAll = createAsyncThunk(
  "/crm/downloadallorders",
  async (data, { rejectWithValue }) => {
    try {
      console.log("insidde asyncthunk");
      const resp = await api.downloadAllapi(data);
      // console.log(resp, "resp 1");
      if (resp.status === 200) {
        // console.log(resp, "resp");

        return resp?.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const CashbackClaimsStatusMaster = createAsyncThunk(
  "/crm/claimsstatusmaster",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.CashbackClaimsStatusMaster();
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getInfoByClickId = createAsyncThunk(
  "/crm/infobyclickid",
  async (formData, { rejectWithValue }) => {
    try {
      console.log(formData, "infobyclickid formData====================");
      const resp = await api.getInfoByClickIdApi(formData);
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const searchByClickId = createAsyncThunk(
  "/crm/searchbyclicks",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.searchByClickId(data);
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const SearchByClickIdByDate = createAsyncThunk(
  "/crm/clicksbydate",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.SearchByClickIdByDate(data);
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const showCashbackClaims = createAsyncThunk(
  "/crm/showcashbackclaims",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.showCashbackClaims(data);
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getPartnerDetails = createAsyncThunk(
  "/crm/getPartnerDetails",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getPartnerDetails();
      if (resp.status === 200) {
        return resp.data;
      } else {
        message.error(
          resp.message ? resp.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateCashbackClaimStatus = createAsyncThunk(
  "/crm/updateclaimstatus",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await api.updateCashbackClaimStatus(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Updated Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const StoreFixedTermsAndConditions = createAsyncThunk(
  "/crm/storestatictermsconditions",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.storeFixedTermsAndConditionsApi();
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getNotificationAlerts = createAsyncThunk(
  "/crm/getNotificationAlerts",
  async (page, { rejectWithValue }) => {
    try {
      const resp = await api.getNotificationAlerts(page);
      if (resp.status === 200) {
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const addRemarkAndApproval = createAsyncThunk(
  "/crm/addRemarkAndApproval",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.addRemarkAndApproval(data);
      if (resp.status === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Updated Successfully!"
        );
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getOrderDetails = createAsyncThunk(
  "/crm/getOrderDetails",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.getOrderDetails(data);
      if (resp.status === 200) {
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const crmSlice = createSlice({
  name: "crm",
  initialState: {
    data: {
      Top10Results: [],
      AllOrdersCrmDetails: [],
      performResultsData: [],
      bulkbuyResultsData: [],
      cashbackClaimsData: [],
      matchPointPostCashbackClaimsData: [],
      top10Orders: [],
      CashbackClaimsStatusMaster: [],
      // getInfoByClickId: [],
      infoByClickId: [],
      partnerRespByClickId: [],
      getAllDateAffClicksId: [],

      searchByClickId: [],
      SearchByClickIdByDate: [],
      userInfo: [],
      orderDetailsInfo: [],
      clicksDataInfo: [],
      userRedemptionInfo: [],
      requestedBalanceInfo: [],
      userBalanceInfo: [],
      requestedBalanceInfo: [],
      redemptiondBalanceInfo: [],
      storeFixedtermsConditionData: [],
      clicksByDate: [],
      notificationAlerts: {},
      orderDetails: [],
      partnerDetail: [],
      cancelledAmount: []
    },
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Top10Results.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Top10Results.fulfilled, (state, action) => {
      state.data.Top10Results = action.payload.data;
      state.data.top10Orders = action.payload.top10Orders;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(Top10Results.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    ////All Order Crm Details ///

    builder.addCase(AllOrdersCrmDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AllOrdersCrmDetails.fulfilled, (state, action) => {
      state.data.AllOrdersCrmDetails = {
        data: action.payload.data,
        total_orders_length: action.payload.total_orders_length,
      };
      state.loading = false;
      state.error = null;
    });
    builder.addCase(AllOrdersCrmDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    //// DOWNLOAD ALL DATA  //////////////////////////////////////////////////////////////////
    builder.addCase(DownloadAllData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(DownloadAllData.fulfilled, (state, action) => {
      // state.data.DownloadAllData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(DownloadAllData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    ////PerformanceReports Crm Details ///

    builder.addCase(performanceReportsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(performanceReportsData.fulfilled, (state, action) => {
      state.data.performResultsData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(performanceReportsData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    //////////// bulk buy report //////////////////////////////////////
    builder.addCase(bulkBuyReportsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(bulkBuyReportsData.fulfilled, (state, action) => {
      state.data.bulkbuyResultsData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(bulkBuyReportsData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    ///// download all / //////////////////////////////////
    builder.addCase(DownloadAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(DownloadAll.fulfilled, (state, action) => {
      // state.data.performResultsData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(DownloadAll.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    ///   SHOW CASHBACK CLAIM DATA    /////

    builder.addCase(showCashbackClaims.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showCashbackClaims.fulfilled, (state, action) => {
      state.data.cashbackClaimsData = action.payload.data;
      state.data.matchPointPostCashbackClaimsData =
        action.payload.matchPointPostCashbackClaims;

      state.loading = false;
      state.error = null;
    });
    builder.addCase(showCashbackClaims.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    ///   CashbackClaimsStatusMaster    /////

    builder.addCase(CashbackClaimsStatusMaster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(CashbackClaimsStatusMaster.fulfilled, (state, action) => {
      state.data.CashbackClaimsStatusMaster = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(CashbackClaimsStatusMaster.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //////////////////getInfoByClickId

    builder.addCase(getInfoByClickId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInfoByClickId.fulfilled, (state, action) => {
      // state.data.getInfoByClickId = action.payload.data;
      state.data.infoByClickId = action.payload.data;
      state.data.partnerRespByClickId = action.payload.data1;
      state.data.getAllDateAffClicksId = action.payload.data2;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getInfoByClickId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    /////////////////////searchByClickId

    builder.addCase(searchByClickId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchByClickId.fulfilled, (state, action) => {
      // state.data.userInfo = action.payload;
      state.data.userInfo = action.payload.data;
      state.data.orderDetailsInfo = action.payload.orderDetailsInfo;
      state.data.clicksDataInfo = action.payload.clicksDataInfo;
      state.data.userRedemptionInfo = action.payload.redemptionInfo;
      state.data.redemptiondBalanceInfo =
        action.payload.redemptionPaymentStatus;
      state.data.userBalanceInfo = action.payload.userBalanceInfo;
      state.data.requestedBalanceInfo = action.payload.requestedBalanceInfo;
      state.data.cancelledAmount = action.payload.cancelledAmount
      state.loading = false;
      state.error = null;
    });
    builder.addCase(searchByClickId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    ////////////////SearchByClickIdByDate

    builder.addCase(SearchByClickIdByDate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(SearchByClickIdByDate.fulfilled, (state, action) => {
      state.data.clicksByDate = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(SearchByClickIdByDate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ============================= updateCashbackClaimStatus============================
    builder.addCase(updateCashbackClaimStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCashbackClaimStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateCashbackClaimStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ============================= StoreFixedTermsAndConditions============================
    builder.addCase(StoreFixedTermsAndConditions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(StoreFixedTermsAndConditions.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.storeFixedtermsConditionData = action.payload.data;
    });
    builder.addCase(StoreFixedTermsAndConditions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getNotificationAlerts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNotificationAlerts.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.data.notificationAlerts.data = action.payload.data;
      state.data.notificationAlerts.count = action.payload?.count[0]?.count;
    });
    builder.addCase(getNotificationAlerts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(addRemarkAndApproval.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addRemarkAndApproval.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addRemarkAndApproval.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getOrderDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrderDetails.fulfilled, (state, action) => {
      state.data.orderDetails = action.payload
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getOrderDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getPartnerDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPartnerDetails.fulfilled, (state, action) => {
      state.data.partnerDetail = action.payload
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getPartnerDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { } = crmSlice.actions;

export default crmSlice.reducer;
